<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import type { User } from '@/interfaces/types';
    import Button from '$lib/components/ui/button/button.svelte';
    import { showPoints } from '@/stores/subscription';

    const dispatch = createEventDispatcher();

    export let duration: '30' | '90' | '182' | '365';
    export let user: User;
    export let authenticatedUser: User;
    export let enforceEmailValidation = false;
    export let creatorCanEarnMoney = true;
    export let isEmailValidated = false;
    export let userAvailableCredit = 0;
    export let currencySymbol = '$';
    export let showLoginDialog: boolean;

    const durationConfig = {
        '30': {
            type: 'one-month-subscription',
            label: 'MONTHLY',
            months: 1,
            priceKey: 'subscription1Month',
            percentClass: 'subscribe-30-percent',
        },
        '90': {
            type: 'three-months-subscription',
            label: 'QUARTERLY',
            months: 3,
            priceKey: 'subscription3Months',
            percentClass: 'subscribe-90-percent',
        },
        '182': {
            type: 'six-months-subscription',
            label: 'BIANNUALY',
            months: 6,
            priceKey: 'subscription6Months',
            percentClass: 'subscribe-182-percent',
        },
        '365': {
            type: 'yearly-subscription',
            label: 'ANNUALLY',
            months: 12,
            priceKey: 'subscription12Months',
            percentClass: 'subscribe-365-percent',
        },
    };

    $: config = durationConfig[duration];
    $: price = user && user[config.priceKey]?.price ? Number(user[config.priceKey].price) * config.months : 0;

    function handleClick() {
        if (!authenticatedUser) {
            showLoginDialog = true;
            return;
        }

        if (!isEmailValidated && enforceEmailValidation) {
            return;
        }

        if (!creatorCanEarnMoney) {
            return;
        }

        dispatch('showCheckout', {
            type: config.type,
            recipientId: user.id,
            amount: price,
            firstName: authenticatedUser.first_name,
            lastName: authenticatedUser.last_name,
            billingAddress: authenticatedUser.billing_address,
            country: authenticatedUser.country,
            city: authenticatedUser.city,
            state: authenticatedUser.state,
            postcode: authenticatedUser.postcode,
            availableCredit: userAvailableCredit,
            username: user.username,
            name: user.name,
            avatar: user.avatar,
        });
    }

    $: isDisabled = authenticatedUser && ((!isEmailValidated && enforceEmailValidation) || !creatorCanEarnMoney);

    $: buttonTitle = !authenticatedUser
        ? ''
        : !isEmailValidated && enforceEmailValidation
          ? 'Please verify your account'
          : !creatorCanEarnMoney
            ? 'This creator cannot earn money yet'
            : '';
</script>

<Button
    variant="outline"
    size="lg"
    class="relative mb-2 mt-3 flex w-full justify-between border-2 px-3 {isDisabled
        ? 'cursor-not-allowed opacity-50'
        : ''}"
    on:click="{handleClick}"
    disabled="{isDisabled}"
    title="{buttonTitle}"
>
    <span class="font-mona-expanded absolute !text-5xl !font-bold !leading-8 !text-bmn-900 !text-opacity-5">
        {config.label}
    </span>
    <span class="font-mona-expanded z-20 flex items-center text-2xl">
        <span class="subscription-price-value-{duration}">
            {currencySymbol}{price}
        </span>
        &nbsp;&nbsp;
        <span class="!text-lg !font-medium">
            / {config.months}
            {config.months === 1 ? 'month' : 'months'}
        </span>
    </span>
    <span class="{config.percentClass} font-mona-expanded text-lg font-normal"></span>
</Button>
